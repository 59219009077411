import { useEffect, useState } from "react";

import { Box, CircularProgress, Stack } from "@mui/material";

import { SortOrderBy } from "shared/components/CollapsibleTable/types";
import { generateOrderBy } from "shared/components/CollapsibleTable/utils";
import DashboardHeader from "shared/components/DashboardHeader/DashboardHeader";
import EmptyState from "shared/components/EmptyState/EmptyState";
import {
  Order_By,
  Requests_Bool_Exp,
  useGetAllRequestsShopQuery,
} from "shared/generated/graphql";
import { useDebounce } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { useAuth } from "../../providers/AuthProvider";
import { useShopSearch } from "../useShopSearch";

import RequestsDashboardTable from "./RequestsDashboardTable";

const RequestsDashboard = () => {
  const { shopId } = useAuth();
  const { setRequestIds } = useUnreadRequests();
  const { shopSearch, updateShopSearch } = useShopSearch();
  const debouncedSearchTerm = useDebounce(shopSearch.search);

  const [orderByNew, setOrderByNew] = useState<SortOrderBy>({
    field: "id",
    direction: Order_By.Desc,
  });

  const [orderByInProgress, setOrderByInProgress] = useState<SortOrderBy>({
    field: "id",
    direction: Order_By.Desc,
  });

  const [orderByDone, setOrderByDone] = useState<SortOrderBy>({
    field: "id",
    direction: Order_By.Desc,
  });

  const [datesForClosed] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );

  const { data, loading } = useGetAllRequestsShopQuery({
    variables: {
      shopId: shopId ?? 0,
      filterDone: getSearchFilters(debouncedSearchTerm),
      filterInProgress: getSearchFilters(debouncedSearchTerm),
      filterNew: getSearchFilters(debouncedSearchTerm),
      orderByDone: generateOrderBy(orderByDone.field, orderByDone.direction),
      orderByInProgress: generateOrderBy(
        orderByInProgress.field,
        orderByInProgress.direction
      ),
      orderByNew: generateOrderBy(orderByNew.field, orderByNew.direction),
      datesForClosed,
    },
    pollInterval: 10_000,
    skip: !shopId,
  });

  const showEmptyState =
    !data?.requestsNew.length &&
    !data?.requestsInProgress.length &&
    !data?.requestsDone.length &&
    !loading;

  //combine all requests into one array of request ids
  // Effect to compare previous and current request IDs
  useEffect(() => {
    //return if all requests are undefined, null or empty
    if (
      !data?.requestsNew.length &&
      !data?.requestsInProgress?.length &&
      !data?.requestsDone.length
    ) {
      return;
    }
    const requestIds = [
      ...(data?.requestsNew?.map((request) => request.id) || []),
      ...(data?.requestsInProgress?.map((request) => request.id) || []),
      ...(data?.requestsDone?.map((request) => request.id) || []),
    ];
    setRequestIds(requestIds);
  }, [data, setRequestIds]);

  return (
    <Box px={{ md: 2, xs: 0 }}>
      <Stack direction={"row"}>
        <DashboardHeader
          title={"Requests"}
          searchTerm={shopSearch.search}
          updateSearchTerm={(searchTerm) => {
            updateShopSearch({ search: searchTerm });
          }}
        />

        {loading && (
          <Box alignSelf={"center"} ml={1} mt={0.4}>
            <CircularProgress size={20} color="secondary" />
          </Box>
        )}
      </Stack>
      <Stack gap={3}>
        <RequestsDashboardTable
          requests={data?.requestsNew}
          title="New Requests"
          loading={loading}
          orderBy={orderByNew}
          setOrderBy={setOrderByNew}
        />
        <RequestsDashboardTable
          requests={data?.requestsInProgress}
          title="In Progress"
          loading={loading}
          orderBy={orderByInProgress}
          setOrderBy={setOrderByInProgress}
        />
        <RequestsDashboardTable
          requests={data?.requestsDone}
          title="Done"
          loading={loading}
          orderBy={orderByDone}
          setOrderBy={setOrderByDone}
        />
        {showEmptyState && <EmptyState />}
      </Stack>
    </Box>
  );
};

function getSearchFilters(search: string): Requests_Bool_Exp {
  if (!search) {
    return {};
  }

  const searchTerm = `%${search}%`;

  if (!isNaN(+search)) {
    return {
      _or: [
        { id: { _eq: Number(search) } },
        { vehicle: { year: { _ilike: searchTerm } } },
      ],
    };
  } else {
    return {
      _or: [
        { vehicle: { make: { _ilike: searchTerm } } },
        { vehicle: { model: { _ilike: searchTerm } } },
        { vehicle: { plate: { _ilike: searchTerm } } },
        { vehicle: { vin: { _ilike: searchTerm } } },
        {
          vehicle: {
            usersVehicles: {
              carNickname: { _ilike: searchTerm },
            },
          },
        },
        {
          vehicle: {
            fleetVehicles: {
              nickname: { _ilike: searchTerm },
            },
          },
        },
        {
          services: {
            description: { _ilike: searchTerm },
          },
        },
        {
          serviceAdvisor: {
            email: { _ilike: searchTerm },
          },
        },
      ],
    };
  }
}

export default RequestsDashboard;
